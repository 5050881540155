var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mt-10",class:_vm.darkMode && _vm.$i18n.locale === 'en'
        ? 'section-title-ourbestseller-dark'
        : 'section-title-ourbestseller'},[_vm._v("\n    "+_vm._s(_vm.$t('exploreOurBestSellers'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"max-w-1600 mx-auto 2xl:px-8"},[_c('div',{staticClass:"grid gap-4 text-center px-2 mt-10 grid-cols-2 lg:grid-cols-4"},_vm._l((_vm.OurbestSellers),function(product,index){return _c('div',{key:index},[_c('ProductCard',{attrs:{"image":product.media,"title":product.title,"price":product.price,"undiscounted-price":product.undiscountedPrice,"collection-price":product.collectionPrice
              ? product.collectionPrice[_vm.$i18n.localeProperties.currencyCode]
              : null,"collection-undiscounted-price":product.collectionUndiscountedPrice
              ? product.collectionUndiscountedPrice[
                  _vm.$i18n.localeProperties.currencyCode
                ]
              : null,"show-undiscounted-price":product.showUndiscountedPrice,"to":product.to,"badge":product.badge,"custom-badge":product.customBadge,"custom-badge-image":product.customBadgeImage,"custom-badge-text":product.customBadgeText,"custom-badge-class":product.customBadgeClass,"is-from":product.isFrom,"lazy":"","grow-on-hover":"","colored-product-price":product.coloredProductPrice}})],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"max-w-1600 mx-auto 2xl:px-8"},[_c('div',{staticClass:"grid gap-4 text-center px-2 mt-10 grid-cols-2 lg:grid-cols-4"},_vm._l((_vm.OurbestSellers2),function(product,index){return _c('div',{key:index},[_c('ProductCard',{attrs:{"image":product.media,"title":product.title,"price":product.price,"undiscounted-price":product.undiscountedPrice,"collection-price":product.collectionPrice
              ? product.collectionPrice[_vm.$i18n.localeProperties.currencyCode]
              : null,"collection-undiscounted-price":product.collectionUndiscountedPrice
              ? product.collectionUndiscountedPrice[
                  _vm.$i18n.localeProperties.currencyCode
                ]
              : null,"show-undiscounted-price":product.showUndiscountedPrice,"to":product.to,"badge":product.badge,"custom-badge":product.customBadge,"custom-badge-image":product.customBadgeImage,"custom-badge-text":product.customBadgeText,"custom-badge-class":product.customBadgeClass,"lazy":"","grow-on-hover":"","is-full-height":""}})],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }