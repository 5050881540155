//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { productsWithPrices } from '@/plugins/utilities'
export default {
  components: {
    ProductCard: () => import('@/containers/theme/ProductCard'),
  },
  props: {
    prices: {
      type: Array,
      default: () => [],
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    OurbestSellers() {
      if (this.prices) {
        return productsWithPrices(
          this.$t('homePage.ourbestSellersProducts'),
          this.prices
        )
      } else {
        return this.$t('homePage.ourbestSellersProducts')
      }
    },
    OurbestSellers2() {
      if (this.prices) {
        return productsWithPrices(
          this.$t('homePage.ourbestSellersProducts2'),
          this.prices
        )
      } else {
        return this.$t('homePage.ourbestSellersProducts2')
      }
    },
  },
}
